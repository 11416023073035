import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map } from 'rxjs/operators';
import { environment } from "../../../../environments/environment";
import { UtilService } from './util.service';

@Injectable({
  providedIn: 'root'
})
export class AgentService {

  baseUrl = environment.apiUrl + environment.version

  constructor(
    private http:HttpClient,
    private util: UtilService
  ) { }
  agentTransaction(data: any) {
    const options: any = {
      params: data,
    };
    return this.http.get(`${this.baseUrl}admin/agent/transactionList`, options) .pipe(
      map((response: any) => {
          if (data.isCSV) {
              return window.open(response.data);
          }
          return response;
      }),
      catchError(this.util.handleError('listUser', []))
  );
  }

  agentCredit(data) {
    return this.http.post(`${this.baseUrl}admin/agent/creditChip`, data).pipe(
      map((response: any) => response),
      catchError(this.util.handleError("agentCredit", []))
    );
  }

  agentDebit(data) {
    return this.http.post(`${this.baseUrl}admin/agent/debitChip`, data).pipe(
      map((response: any) => response),
      catchError(this.util.handleError("agentCredit", []))
    );
  }

    //get agent by id
    getAgent(id) {
      return this.http.get(`${this.baseUrl}admin/agent/get/${id}`).pipe(
        map((response: any) => response),
        catchError(this.util.handleError("getAgent", []))
      );
    }
  
    //get agent name
    agentName(data) {
      // console.log(data)
      return this.http.post(`${this.baseUrl}admin/agent/agentName`, data).pipe(
        map((response: any) => response),
        catchError(this.util.handleError("agentName", []))
      );
    }
  
    // list of agent in club
    getClubAgent(data) {
      const option = {
        params: data
      };
      return this.http.get(`${this.baseUrl}admin/agent/list`, option).pipe(
        catchError(this.util.handleError("getUser", []))
      )
    }

}
